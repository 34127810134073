var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.recipe
    ? _c(
        "v-container",
        { staticClass: "wp-page recipe-detail", on: { click: _vm.clicked } },
        [
          _c("breadcrumb", { attrs: { items: _vm.breadcrumb } }),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-img", {
                    staticClass: "mb-3 rounded",
                    attrs: {
                      src: _vm.recipe.featured_image_url,
                      alt: `ricetta-${_vm.recipe.slug}`
                    }
                  }),
                  _c("h1", {
                    staticClass: "my-6",
                    domProps: { innerHTML: _vm._s(_vm.recipe.title.rendered) }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "recipe-info pa-4",
                          attrs: { cols: "12", md: "6" }
                        },
                        [
                          _vm.recipe.course && _vm.recipe.course.length
                            ? _c(
                                "v-chip",
                                {
                                  staticClass:
                                    "font-weight-bold text-uppercase mb-n4",
                                  attrs: { color: "primary" }
                                },
                                [_vm._v(_vm._s(_vm.recipe.course))]
                              )
                            : _vm._e(),
                          _c("h2", { staticClass: "py-4" }, [
                            _vm._v(_vm._s(_vm.$t("recipes.description")))
                          ]),
                          _c("div", {
                            domProps: {
                              innerHTML: _vm._s(_vm.recipe.content.unrendered)
                            }
                          }),
                          _c("v-divider"),
                          _c("div", { staticClass: "recipe-instructions" }, [
                            _c("h2", { staticClass: "py-4" }, [
                              _vm._v(_vm._s(_vm.$t("recipes.steps")))
                            ]),
                            _c(
                              "ol",
                              _vm._l(
                                _vm.recipe.recipe_instructions[0],
                                function(instruction, idx) {
                                  return _c("li", {
                                    key: idx,
                                    staticClass: "mb-3",
                                    domProps: {
                                      innerHTML: _vm._s(instruction.description)
                                    }
                                  })
                                }
                              ),
                              0
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "recipe-ingrediente pa-4",
                          attrs: { cols: "12", md: "6" }
                        },
                        [
                          _vm.ingredients && _vm.ingredients.length
                            ? _c(
                                "div",
                                [
                                  _vm.recipe.recipe_ingredients
                                    ? _c(
                                        "v-list",
                                        { staticClass: "w-100 rounded-md" },
                                        [
                                          _c("h2", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "recipes.ingredients.title"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]),
                                          _vm._l(_vm.ingredients, function(
                                            ingredient,
                                            index
                                          ) {
                                            return _c(
                                              "v-list-item",
                                              {
                                                key: ingredient.ingredient_id,
                                                staticClass:
                                                  "ingrediets-list px-0"
                                              },
                                              [
                                                _c(
                                                  "v-list-item-content",
                                                  [
                                                    _c("IngredientCard", {
                                                      attrs: {
                                                        product: ingredient
                                                      }
                                                    }),
                                                    index <
                                                    _vm.ingredients.length - 1
                                                      ? _c("v-divider")
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          })
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass:
                                        "mt-3 d-flex justify-space-between"
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", sm: "6" } },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "w-100 recipe-btn bg-white",
                                              attrs: {
                                                outlined: "",
                                                color: "secondary",
                                                "x-large": "",
                                                elevation: "0"
                                              },
                                              on: {
                                                click: _vm.addAllToFavorites
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("recipes.addItem")
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", sm: "6" } },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "w-100 recipe-btn",
                                              attrs: {
                                                elevation: "0",
                                                color: "primary",
                                                "x-large": ""
                                              },
                                              on: { click: _vm.addAllToCart }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("recipes.addToCart")
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _c("div", [
                                _c("h2", [_vm._v("Nessun ingrediente")])
                              ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "d-flex align-center recipe-detail-go-back my-4",
                      attrs: {
                        to: {
                          name: _vm.routeName
                        }
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "pr-2", attrs: { color: "primary" } },
                        [_vm._v("$chevronLeft")]
                      ),
                      _c(
                        "span",
                        {
                          staticClass:
                            "font-weight-bold text-uppercase primary--text"
                        },
                        [_vm._v(_vm._s(_vm.$t("recipes.goBack")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }